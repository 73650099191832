.container {
  position: relative;
  height: 87vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 60%;
}

.text-overlay {
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  text-align: center;
  width: 90%;
  max-width: 1200px;
}

.heading {
  font-size: clamp(2rem, 4vw, 3.5rem);
  margin-bottom: 2rem;
  line-height: 1.2;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.button {
  box-sizing: border-box;
  text-wrap: nowrap;
  width: 100%;
  max-width: 200px;
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #5774f6;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #4d67db;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contactSection {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #f8f9fa;
}

.contactHeading {
  font-size: clamp(1.8rem, 3vw, 2.5rem);
  color: #333;
  margin-bottom: 1rem;
}

.contactText {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem auto;
  animation: fadeInOut 6s infinite;
}

.contactButton {
  display: inline-block;
  padding: 15px 40px;
  background-color: #5774f6;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.contactButton:hover {
  background-color: #4d67db;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Add these keyframes at the top or bottom of your Home.module.css file */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}

.centerCard {
  transform: scale(1.05);
  transition: all 0.3s ease;
  z-index: 2;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.card {
  height: auto;
  min-height: 350px;
  padding: 1.5rem;
  margin: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  transform: scale(0.95);
}

.card:hover {
  transform: translateY(-5px) scale(0.95);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.centerCard:hover {
  transform: translateY(-5px) scale(1.05);
}

.cardTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.cardDescription {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
  flex-grow: 0;
}

.cardHighlight {
  font-size: 0.9rem;
  color: #5774f4;
  font-weight: bold;
  margin-bottom: 1rem;
}

.cardList {
  list-style-type: disc;
  margin: 0;
  padding: 0 1.5rem;
  text-align: left;
  color: #666;
  flex-grow: 1;
}

.cardList li {
  margin: 0.5rem 0;
  line-height: 1.4;
}

.carouselHeader {
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.carouselHeaderHighlight {
  font-size: 1.2rem;
  color: #5774f4;
  font-weight: bold;
}

/* Extra Extra large screens (4K) */
@media (min-width: 2000px) {
  .container {
    height: 92vh;
  }
  .text-overlay {
    width: 75%;
  }
  .heading {
    font-size: clamp(3.5rem, 5vw, 4.5rem);
  }
  .button {
    max-width: 300px;
    padding: 25px 50px;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
  }
  .contactSection {
    padding: 8rem 2rem;
  }
  .contactHeading {
    font-size: clamp(2.5rem, 4vw, 3.5rem);
  }
  .contactText {
    font-size: clamp(1.2rem, 2vw, 1.8rem);
    max-width: 1000px;
    margin-bottom: 3rem;
  }
  .contactButton {
    padding: 25px 60px;
    font-size: 1.3rem;
  }
}

/* 1440p screens */
@media (min-width: 1600px) and (max-width: 1999px) {
  .container {
    height: 91vh;
  }
  .heading {
    font-size: clamp(3rem, 4.5vw, 4rem);
  }
  .button {
    max-width: 250px;
    padding: 20px 40px;
    font-size: clamp(1.1rem, 1.8vw, 1.3rem);
  }
  .contactSection {
    padding: 6rem 2rem;
  }
  .contactHeading {
    font-size: clamp(2.2rem, 3.5vw, 3rem);
  }
  .contactText {
    font-size: clamp(1.1rem, 1.8vw, 1.5rem);
    max-width: 800px;
    margin-bottom: 2.5rem;
  }
  .contactButton {
    padding: 20px 50px;
    font-size: 1.2rem;
  }
}

/* Large screens */
@media (min-width: 1208px) {
  .container {
    height: 90vh;
  }
  .text-overlay {
    width: 80%;
  }
  .button {
    padding: 18px 36px;
  }
  .contactButton {
    padding: 18px 48px;
  }
}

/* Medium screens */
@media(max-width: 1207px) {
  .container {
    height: 89vh;
  }
  .contactSection {
    padding: 4rem 2rem;
  }
}

/* Mobile screens */
@media (max-width: 767px) {
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    max-width: 50%;
    padding: 12px 24px;
  }
  .buttonsContainer {
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-overlay {
    height: 82%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contactSection {
    padding: 3rem 1rem;
  }
  .contactButton {
    padding: 12px 32px;
    width: 80%;
    max-width: 300px;
  }
  .contactText {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }
  .contactText {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    color: #666;
    max-width: 600px;
    margin: 0 auto 2rem auto;
    animation: fadeInOut 6s infinite;
  }
  
  .centerCard {
    transform: none;
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .card {
    transform: none;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .centerCard:hover {
    transform: translateY(-5px);
  }
  
  .carouselContainer {
    padding: 0;
  }
  
  .carousel {
    max-width: 100%;
    overflow: visible;
    padding: 0;
    margin: 0 auto;
  }

  .carouselHeader {
    margin-bottom: 1.5rem;
    padding: 0 1rem;
  }

  .card {
    margin: 0 0.5rem;
    padding: 1.25rem;
    min-height: 300px;
    width: calc(100% - 1rem);
    box-sizing: border-box;
  }

  .cardTitle {
    font-size: 1.3rem;
    margin-bottom: 0.75rem;
  }

  .cardDescription {
    font-size: 0.95rem;
    margin-bottom: 0.75rem;
  }

  .cardList {
    padding: 0 1.25rem;
    margin-bottom: 0.5rem;
  }

  .cardList li {
    margin: 0.4rem 0;
    font-size: 0.95rem;
  }

  .dots {
    bottom: -30px;
  }

  .dots li {
    margin: 0 4px;
  }

  .dots li button {
    width: 10px;
    height: 10px;
  }

  .dots li button:before {
    font-size: 10px;
    width: 10px;
    height: 10px;
  }

  .carouselContainer {
    padding: 0 0 3rem 0;
  }
}

@media (max-width: 480px) {
  .carousel {
    padding: 0;
  }

  .card {
    margin: 0 0.375rem;
    padding: 1rem;
    min-height: 280px;
  }

  .cardTitle {
    font-size: 1.2rem;
  }

  .cardDescription {
    font-size: 0.9rem;
  }

  .cardList {
    padding: 0 1rem;
  }

  .cardList li {
    font-size: 0.9rem;
    margin: 0.3rem 0;
  }
}

.aboutSummarySection {
  padding: 4rem 2rem;
  background-color: #f8f9fa;
  width: 100%;
  box-sizing: border-box;
}

.aboutSummaryContent {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.aboutSummaryContent h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.aboutSummaryContent p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.highlight {
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.highlight:hover {
  transform: translateY(-5px);
}

.highlight h3 {
  color: #5774f6;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.highlight p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

@media (max-width: 1024px) {
  .benefitsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .benefitsGrid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1.5rem;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }
  
  .highlight {
    width: 100%;
    padding: 1.25rem;
    margin: 0;
    box-sizing: border-box;
  }
  
  .aboutSummarySection {
    padding: 2.5rem 0;
  }

  .aboutSummaryContent {
    width: 100%;
    padding: 0;
  }

  .aboutSummaryContent h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  .aboutSummaryContent p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
  }

  .highlight h3 {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
  }

  .highlight p {
    font-size: 0.95rem;
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .aboutSummarySection {
    padding: 2rem 0;
  }

  .benefitsGrid {
    gap: 0.875rem;
    padding: 0 0.75rem;
    width: calc(100% - 1.5rem);
  }

  .highlight {
    padding: 1rem;
  }

  .aboutSummaryContent h2 {
    font-size: 1.6rem;
    padding: 0 0.75rem;
  }

  .aboutSummaryContent p {
    padding: 0 0.75rem;
  }

  .highlight h3 {
    font-size: 1.1rem;
  }

  .highlight p {
    font-size: 0.9rem;
  }
}

.carouselContainer {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1rem 0 4rem 0;
}

.carousel {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.sliderWrapper {
  padding: 2rem 0;
}

/* Dots styling */
.dots {
  bottom: -40px;
}

.dots li {
  margin: 0 6px;
}

.dots li button {
  width: 12px;
  height: 12px;
}

.dots li button:before {
  color: #5774f6;
  opacity: 0.25;
  font-size: 12px;
  width: 12px;
  height: 12px;
  transition: all 0.3s ease;
}

.dots li.slick-active button:before {
  color: #5774f6;
  opacity: 1;
}

.contactButtonSection {
  text-align: center;
  padding: 2rem 0;
  background-color: #f8f9fa;
}

.contactButtonSection .contactButton {
  display: inline-block;
  padding: 15px 40px;
  background-color: #5774f6;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.contactButtonSection .contactButton:hover {
  background-color: #4d67db;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .contactButtonSection {
    padding: 1.5rem 0;
  }
  
  .contactButtonSection .contactButton {
    padding: 12px 32px;
    width: 80%;
    max-width: 300px;
  }
}

